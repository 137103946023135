/* ==========================================================================
   Modal
   ========================================================================== */

//Custom
.modal {
  .modal-content {
    border-radius: 0;
  }
  .modal-header {
    display: flex;
    align-items: center;
    border-radius: 0;
    position: relative;
    &.dark {
      background: $gray-800;
    }
    p {
      color: $white;
    }
  }
}

.modal-dialog {
  // When fading in the modal, animate it without sliding
  .modal.fade & {
    @include transition($modal-transition);
    transform: translate(0, 0%);
  }
  .modal.show & {
    transform: translate(0, 0);
  }

  // When fading in the modal, animate it to slide up
  .modal.fade.fadeinup & {
    @include transition($modal-transition);
    transform: translate(0, 25%);
  }
  .modal.show.fadeinup & {
    transform: translate(0, 0);
  }

  // When fading in the modal, animate it to slide down
  .modal.fade.fadeindown & {
    @include transition($modal-transition);
    transform: translate(0, -25%);
  }
  .modal.show.fadeindown & {
    transform: translate(0, 0);
  }

  // When fading in the modal, animate it to slide up (on SM down)
  @include media-breakpoint-down(sm) {
    .modal.fade.fadeinup-sm-down & {
      @include transition($modal-transition);
      transform: translate(0, 25%);
    }
    .modal.show.fadeinup-sm-down & {
      transform: translate(0, 0);
    }
  }

  // When fading in the modal, animate it to slide down (on SM down)
  @include media-breakpoint-down(sm) {
    .modal.fade.fadeindown-sm-down & {
      @include transition($modal-transition);
      transform: translate(0, -25%);
    }
    .modal.show.fadeindown-sm-down & {
      transform: translate(0, 0);
    }
  }

  // General modal overrider for box shadow
  .modal-content {
    border: none;
    box-shadow: $box-shadow-sm;
  }

  // General modal overrider for close
  .close {
    position: absolute;
    right: 1rem;
    top: 0.8rem;
    @include transition($transition-base);
    &:focus {
      outline: none !important;
    }
  }
}

// Modal take up full screen on mobile
.modal-full {
  @include media-breakpoint-down(sm) {
    .modal-dialog {
      margin: 0 auto;
    }
    .modal-content {
      height: 100vh;
    }
  }
}

/* Modal Success, Modal Alert & Modal Warning
-------------------------------------------------- */
.f-modal-alert .f-modal-icon {
  border-radius: 50%;
  border: 4px solid gray;
  box-sizing: content-box;
  height: 80px;
  margin: 20px auto;
  padding: 0;
  position: relative;
  width: 80px;

  /*Success icon*/
  &.f-modal-success,
  &.f-modal-error {
    border-color: #a5dc86;

    &:after,
    &:before {
      background: #fff;
      content: "";
      height: 120px;
      position: absolute;
      transform: rotate(45deg);
      width: 60px;
    }

    &:before {
      border-radius: 120px 0 0 120px;
      left: -33px;
      top: -7px;
      transform-origin: 60px 60px;
      transform: rotate(-45deg);
    }

    &:after {
      border-radius: 0 120px 120px 0;
      left: 30px;
      top: -11px;
      transform-origin: 0 60px;
      transform: rotate(-45deg);
    }

    .f-modal-placeholder {
      border-radius: 50%;
      border: 4px solid rgba(165, 220, 134, 0.2);
      box-sizing: content-box;
      height: 80px;
      left: -4px;
      position: absolute;
      top: -4px;
      width: 80px;
      z-index: 2;
    }

    .f-modal-fix {
      background-color: #fff;
      height: 90px;
      left: 28px;
      position: absolute;
      top: 8px;
      transform: rotate(-45deg);
      width: 5px;
      z-index: 1;
    }

    .f-modal-line {
      background-color: #a5dc86;
      border-radius: 2px;
      display: block;
      height: 5px;
      position: absolute;
      z-index: 2;

      &.f-modal-tip {
        left: 14px;
        top: 46px;
        transform: rotate(45deg);
        width: 25px;
      }

      &.f-modal-long {
        right: 8px;
        top: 38px;
        transform: rotate(-45deg);
        width: 47px;
      }
    }
  }

  /*Error icon*/
  &.f-modal-error {
    border-color: #f27474;

    .f-modal-x-mark {
      display: block;
      position: relative;
      z-index: 2;
    }

    .f-modal-placeholder {
      border: 4px solid rgba(200, 0, 0, 0.2);
    }

    .f-modal-line {
      background-color: #f27474;
      top: 37px;
      width: 47px;

      &.f-modal-left {
        left: 17px;
        transform: rotate(45deg);
      }

      &.f-modal-right {
        right: 16px;
        transform: rotate(-45deg);
      }
    }
  }

  /*Warning icon*/
  &.f-modal-warning {
    border-color: #f8bb86;

    &:before {
      animation: pulseWarning 2s linear infinite;
      background-color: #fff;
      border-radius: 50%;
      content: "";
      display: inline-block;
      height: 100%;
      opacity: 0;
      position: absolute;
      width: 100%;
      left: 0;
    }

    &:after {
      background-color: #fff;
      border-radius: 50%;
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 1;
    }
  }

  &.f-modal-warning .f-modal-body {
    background-color: #f8bb86;
    border-radius: 2px;
    height: 47px;
    left: 50%;
    margin-left: -2px;
    position: absolute;
    top: 10px;
    width: 5px;
    z-index: 2;
  }

  &.f-modal-warning .f-modal-dot {
    background-color: #f8bb86;
    border-radius: 50%;
    bottom: 10px;
    height: 7px;
    left: 50%;
    margin-left: -3px;
    position: absolute;
    width: 7px;
    z-index: 2;
  }

  + .f-modal-icon {
    margin-top: 50px;
  }
}
