/* ==========================================================================
   Grid
   ========================================================================== */

// Default row overrider
.row {
  margin-right: -10px; /* Originally 15 */
  margin-left: -10px; /* Originally 15 */
}
.row > [class^="col"] {
  padding-right: 10px; /* Originally 15 */
  padding-left: 10px; /* Originally 15 */
}

// Row / col variations
.row-0 {
  margin-right: 0px;
  margin-left: 0px;
  & > [class^="col"] {
    padding-right: 0px;
    padding-left: 0px;
  }
}

.row-10px {
  margin-right: -5px;
  margin-left: -5px;
  & > [class^="col"] {
    padding-right: 5px;
    padding-left: 5px;
  }
}
.row-20px {
  margin-right: -10px;
  margin-left: -10px;
  & > [class^="col"] {
    padding-right: 10px;
    padding-left: 10px;
  }
}
.row-30px {
  margin-right: -15px;
  margin-left: -15px;
  & > [class^="col"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.row-40px {
  margin-right: -20px;
  margin-left: -20px;
  & > [class^="col"] {
    padding-right: 20px;
    padding-left: 20px;
  }
}
.row-50px {
  margin-right: -25px;
  margin-left: -25px;
  & > [class^="col"] {
    padding-right: 25px;
    padding-left: 25px;
  }
}
.row-60px {
  margin-right: -30px;
  margin-left: -30px;
  & > [class^="col"] {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.row-1 {
  margin-right: -($spacer * 0.25);
  margin-left: -($spacer * 0.25);
  & > [class^="col"] {
    padding-right: ($spacer * 0.25);
    padding-left: ($spacer * 0.25);
  }
}
.row-2 {
  margin-right: -($spacer * 0.5);
  margin-left: -($spacer * 0.5);
  & > [class^="col"] {
    padding-right: ($spacer * 0.5);
    padding-left: ($spacer * 0.5);
  }
}
.row-3 {
  margin-right: -($spacer * 1);
  margin-left: -($spacer * 1);
  & > [class^="col"] {
    padding-right: ($spacer * 1);
    padding-left: ($spacer * 1);
  }
}
.row-4 {
  margin-right: -($spacer * 2);
  margin-left: -($spacer * 2);
  & > [class^="col"] {
    padding-right: ($spacer * 2);
    padding-left: ($spacer * 2);
  }
}
.row-5 {
  margin-right: -($spacer * 3);
  margin-left: -($spacer * 3);
  & > [class^="col"] {
    padding-right: ($spacer * 3);
    padding-left: ($spacer * 3);
  }
}

@include media-breakpoint-up(md) {
  .row-md-up-1 {
    margin-right: -($spacer * 0.25) !important;
    margin-left: -($spacer * 0.25) !important;
    & > [class^="col"] {
      padding-right: ($spacer * 0.25) !important;
      padding-left: ($spacer * 0.25) !important;
    }
  }
  .row-md-up-2 {
    margin-right: -($spacer * 0.5) !important;
    margin-left: -($spacer * 0.5) !important;
    & > [class^="col"] {
      padding-right: ($spacer * 0.5) !important;
      padding-left: ($spacer * 0.5) !important;
    }
  }
  .row-md-up-3 {
    margin-right: -($spacer * 1) !important;
    margin-left: -($spacer * 1) !important;
    & > [class^="col"] {
      padding-right: ($spacer * 1) !important;
      padding-left: ($spacer * 1) !important;
    }
  }
  .row-md-up-4 {
    margin-right: -($spacer * 2) !important;
    margin-left: -($spacer * 2) !important;
    & > [class^="col"] {
      padding-right: ($spacer * 2) !important;
      padding-left: ($spacer * 2) !important;
    }
  }
  .row-md-up-5 {
    margin-right: -($spacer * 3) !important;
    margin-left: -($spacer * 3) !important;
    & > [class^="col"] {
      padding-right: ($spacer * 3) !important;
      padding-left: ($spacer * 3) !important;
    }
  }
}
