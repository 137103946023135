/* ==========================================================================
   Position
   ========================================================================== */

// Background feature position
.bg-feature {
  height: 200px;
  @include media-breakpoint-up(md) {
    position: absolute; 
    height: 100%;
    width: 50%; 
  }
  &-left {left: 0%;}
  &-right {left: 50%;}
}

// Vertically & Horizontally center. Note: parent must have position: relative
.center-both {
  position: absolute;
  top: 50%;
  left: 50%;
  @include prefix(transform, translate(-50%, -50%), 'webkit' 'ms');
}
.center-md-both {
  @include media-breakpoint-up(md) {
    position: absolute;
    top: 50%;
    left: 50%;
    @include prefix(transform, translate(-50%, -50%), 'webkit' 'ms');
  }
}
.center-sm-down-both {
  @include media-breakpoint-down(sm) {
    position: absolute;
    top: 50%;
    left: 50%;
    @include prefix(transform, translate(-50%, -50%), 'webkit' 'ms');
  }
}