/* ==========================================================================
   Cover
   ========================================================================== */

// Cover basic styling
.cover {
  position: relative;
  height: 400px; // Change this value as needed
  @include media-breakpoint-up(md) {
    height: 400px; // Change this value as needed
  }
  .container {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
  }
  .cover-content {
    width: 100%;
    @include media-breakpoint-up(md) {
      max-width: 500px;
    }
  }
  .cover-title {
    font-size: 2rem;
    color: $white;
    font-family: $font-family-serif;
    @include media-breakpoint-up(md) {
      font-size: 3rem;
    }
    &.cover-title-sm {
      font-size: 2rem;
      font-weight: $font-weight-light;
    }
  }
  .cover-text {
    color: rgba($white, 0.75);
  }
}

// Cover with full screen height
.cover.cover-full {
  height: 100vh;
  margin-top: 0 !important;
  @include media-breakpoint-up(md) {
    height: 100vh;
  }
}

// Cover that changes layout between mobile version & desktop version
.cover-responsive {
  background: url(../../assets/img/sample/bg_rectangle-dark-2.jpg) no-repeat
    center;
  background-size: cover;
  @include media-breakpoint-down(sm) {
    height: auto !important;
    background: none;
    .cover-responsive-img {
      // background: url(../../assets/img/sample/bg_rectangle-dark-2.jpg) no-repeat
      //   center;
      background-size: cover;
      height: 250px;
      position: relative;
    }
    .cover-responsive-overlay {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40%;
      background: linear-gradient(
        to bottom,
        rgba($white, 0) 0%,
        rgba($white, 1) 100%
      );
    }
    .container {
      background: $white;
      .cover-content {
        padding: 1.5rem 0;
        .cover-title {
          color: $body-color;
          z-index: 1;
          position: relative;
        }
        .cover-text {
          color: $gray-400;
        }
      }
    }
    &.bg-gray-50 {
      background: transparent;
      .cover-responsive-overlay {
        background: linear-gradient(
          to bottom,
          rgba($white, 0) 0%,
          rgba($gray-50, 1) 100%
        );
      }
      .container {
        background: $gray-50;
      }
    }
    &.bg-primary {
      background: transparent;
      .cover-responsive-overlay {
        background: linear-gradient(
          to bottom,
          rgba($white, 0) 0%,
          rgba($primary, 1) 100%
        );
      }
      .container {
        background: $primary;
        .cover-title {
          color: $white;
        }
        .cover-text {
          color: rgba($white, 0.75);
        }
      }
    }
    &.bg-dark {
      background: transparent;
      .cover-responsive-overlay {
        background: linear-gradient(
          to bottom,
          rgba($white, 0) 0%,
          rgba($dark, 1) 100%
        );
      }
      .container {
        background: $dark;
        .cover-title {
          color: $white;
        }
        .cover-text {
          color: rgba($white, 0.75);
        }
      }
    }
  }
}

// Make .cover works for carousel
.cover-carousel {
  .carousel,
  .carousel-inner,
  .carousel-item {
    height: 100%;
  }
}

// Carousel with thumbnail
.carousel-indicators-thumb {
  position: relative;
  margin: 0;
  align-items: center;
  bottom: 0;
  z-index: 1;
  display: inherit;
  li {
    margin: 0 0 1rem;
    width: 100%;
    height: auto;
    cursor: pointer;
    @include transition($transition-base);
    &:hover,
    &.active {
      opacity: 0.5;
    }
  }
}

// Carousel circle
.carousel-indicators-circle {
  li {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.25);
    &.active {
      background: $black;
    }
  }
}
