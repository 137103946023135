/* ==========================================================================
   Sizing
   ========================================================================== */

// Custom height, with option to be applied only on MD
@include media-breakpoint-up(md) {
  .h-md-50px { height: 50px; }
  .h-md-100px { height: 100px; }
  .h-md-150px { height: 150px; }
  .h-md-200px { height: 200px; }
}