/* ==========================================================================
   Progress
   ========================================================================== */

.progress {
  background: $gray-70;
  border-radius: $border-radius-sm;
  position: relative;
  font-size: .6rem;
  font-weight: $font-weight-bold;

  .progress-bar-indicator {
    position: absolute;
    width: 1px;
    border: 1px solid $body-color;
    z-index: 2;
    height: 16px;
  }
}